@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
:root{
  --color1: #0b3c5d;
  --color2: #328cc1;
  --color3: #d9b310;
  --color4: #1d2731;
  --color5: #CCCCCC;
}

*, ::before, ::after{
  box-sizing: inherit;
}


html{
  /* provided by Lera Verou at http://projects.verou.me/css3patterns/#madras */
  background:
  radial-gradient(black 15%, transparent 16%) 0 0,
  radial-gradient(black 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color: #1d2731;
  background-color: var(--color4);
  background-size:16px 16px;
  position: relative;
  height: 100%;
}


.app{
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings: 'INLN' 1000, 'SWRM' 1000;
  transform: none;
  box-shadow: -20px 0 15px -8px rgb(0 0 0 /50%);
  background-color: #1d2731;
  background-color: var(--color4);
  -webkit-hyphens:auto;
      -ms-hyphens:auto;
          hyphens:auto;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  overflow-y:auto;
  /* text-shadow: var(--color4) 1px 1px 0; */
}

#root{
  width: 100%;
}

.heading::before{
  content: "";
  display: block;
  width: 100%;
  height: .2rem;
  margin: 0 auto;
  background: #CCCCCC;
  background: var(--color5);
}


.hero::after{
  content: "";
  display: block;
  width: 20%;
  height: .15rem;
  margin: 1rem 0 0 0;
  background: #CCCCCC;
  background: var(--color5);
}

.navline{
  position: fixed;
  bottom: 0;

}

.navline::after{
  content: "";
  display:block;
  width: 100%;
  height: .2rem;
  margin: .5rem auto 2rem auto;
  background: #CCCCCC;
  background: var(--color5);
}
a{
  text-decoration: none;
  color: #CCCCCC;
  color: var(--color5);
  outline: none;
  user-select: none;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
a:active { 
  outline: none; 
}
a:active, :focus { 
  outline: none;
  outline-style: none;
  -moz-outline-style: none; 
}
/* https://stackoverflow.com/questions/814366/how-can-i-remove-the-outline-around-hyperlinks-images */
a:link:focus, :visited:focus {
  outline: none;
}
.link{
  text-decoration: none;
  color: #CCCCCC;
  color: var(--color5);
  z-index: 1;
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.link:hover{
  color: #d9b310;
  color: var(--color3);
}
.link::after{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  padding: 0 2.5rem;
  height:50%;
  bottom: -.5rem;
  background-color: #d9b310;
  background-color: var(--color3);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 300ms ease-in;
 
}
.link:hover::after{
  transform: scaleX(1);
  transform-origin: left;
}
.link i{
  color: #EEEEEE;
  font-weight: bolder;
  margin-right: .2rem;
}
.link:hover i{
  color:#d9b310;
  color:var(--color3)
}

.socials{
  position: fixed;
  top: 70%;
  left: 8%;
  transform: translateY(-30%);
}
.socials::after{
  content: "";
  display:flex;
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left:40%;
  width: .2rem;
  height: 40%;
  background: #CCCCCC;
  background: var(--color5);
}

.social{
  color: #CCCCCC;
  color: var(--color5);
  transform: scale(.9);
  font-size: 3rem;
  transition: transform 500ms ease;
  line-height: 4rem;
}

.social:hover{
  color: #d9b310;
  color: var(--color3);
  transform: scale(1);
}

.mail-line{
  position: fixed;
  top: 17%;
  left: 8%;
  transform: translateY(-83%);
}
.mail-line::before{
  content: "";
  display:flex;
  position: absolute;
  top: -100%;
  left:50%;
  width: .2rem;
  height: 90%;
  background: #CCCCCC;
  background: var(--color5);
}
.mail{ 
  display: block;
  color: #CCCCCC;
  color: var(--color5);
  font-size: 3rem;
  transform: scale(.9);
  transition: transform 500ms ease;
}
.mail:hover{
  color: #d9b310;
  color: var(--color3);
  transform: scale(1);
}

aside h1{
  position: fixed;
  top: 40%;
  left: 8%;
  transform: translateY(-60%);
  color: #d9b310;
  color: var(--color3);
}


.cancel{
  color: #d9b310;
  color: var(--color3);
}

/* media queries  for small devices*/
@media only screen and (max-width: 450px){
  .heading::before{
    content: "";
    display: block;
    width: 100%;
    height: .1rem;
    margin: 0 auto;
    background: #CCCCCC;
    background: var(--color5);
  }
  .navline{
    position: fixed;
    bottom: .3rem;
  
  }
  .navline::after{
    content: "";
    display:block;
    width: 100%;
    height: .1rem;
    margin: .25rem auto 2rem auto;
    background: #CCCCCC;
    background: var(--color5);
  }
  .socials{
    position: absolute;
    top: 70%;
    left: 2%;
    transform: translateY(-30%);
  }
  .socials::after{
    content: "";
    display:flex;
    flex-wrap: wrap;
    position: absolute;
    top: 100%;
    left:35%;
    width: .1rem;
    height: 50%;
    background: #CCCCCC;
    background: var(--color5);
  }
  .social{
    color: #CCCCCC;
    color: var(--color5);
    transform: scale(.9);
    font-size: 1.5rem;
    transition: transform 500ms ease;
    line-height: 2rem;
  }
  aside h1{
    position: absolute;
    top: 40%;
    left: -1%;
    transform: translateY(-60%);
    color: #d9b310;
    color: var(--color3);
  }
  
  .hero::after{
    content: "";
    display: block;
    width: 20%;
    height: .15rem;
    margin: .3rem 0 0 0;
    background: #CCCCCC;
    background: var(--color5);
  }

  .heading::before{
    margin: -1.5rem auto;
  }
  
  .navline::after{
    margin: .5rem auto .5rem auto;
  }

  .mail{  
    font-size: 1.5rem;
  }

  .mail-line{
    position: absolute;
    top: 17%;
    left: 2%;
    transform: translateY(-82%);
  }
  .mail-line::before{
    content: "";
    display:flex;
    position: absolute;
    top: -100%;
    left:45%;
    width: .1rem;
    height: 100%;
    background: #CCCCCC;
    background: var(--color5);
  }
  .link::after{
    width: 70%;
  }
  .navigationbg{
    background: #1d2731;
    background: var(--color4);
    box-shadow: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 ); 
  }
  .burger:hover{
    color: #d9b310;
    color: var(--color3)
  }
}


